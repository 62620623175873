import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Fai LAW | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my profolio site', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Fai LAW',
  subtitle: 'I am a Software Engineer',
  cta: '',
  bg: 'bg.jpg',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile-pic.jpg',
  paragraphOne:
    'I am currently working as a Software Engineer at Willis Towers Watson in Hong Kong. After working in a Hong Kong based Startup Jobable for 4 years, a group was hired into Willis Towers Watson (NASDAQ: WLTW).',
  paragraphTwo:
    "I hold a Bachelor's degree and a Master's degree in Computer Science from the City University of Hong Kong. I am also an AWS Certified Developer Associate and AWS Certified Solution Architect Associate.",
  paragraphThree:
    'Fun Fact: In 2015, I was awarded a Mediallion for excellence and Best of Nation, as a Hong Kong Delegate to WorldSkills São Paulo 2015 in IT Software Solutions for Business.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'workvue.png',
    title: 'Jobable x WTW - WorkVue',
    info:
      'WorkVue is an AI-driven job reinvention software that partnered with Willis Towers Watson. It was awarded as one of the top HR products in 2020.',
    info2:
      'This project was developed during COVID19 outbreak in 2020 on a remote basis. As a core Software Engineer in this project, I worked closely with the team to build the product from scratch. I was responsible for full stack development, including database design, backend and frontend development. The first MVP had been delivered within a month.',
    info3: 'Key Techs: Django/Vue.js/Nuxt.js',
    url: 'https://www.willistowerswatson.com/en-us/Solutions/products/work-vue',
  },
  {
    id: nanoid(),
    img: 'skillsvue.png',
    title: 'Jobable x WTW - SkillsVue',
    info:
      'SkillsVue is a skill based compensation modelling software that partnered with Willis Towers Watson. It provides reliable quotes for talent sourcing based on exact skill requirements.',
    info2:
      'I was responsible for the web scraping part and full stack development from scratch. I worked with different roles (e.g. data scientist and PM) to delivery MVP in a 1.5 months.',
    info3: 'Key Techs: Django/Vue.js/Nuxt.js/scrapy',
    url: 'https://www.willistowerswatson.com/en-US/Solutions/products/SkillsVue',
  },
  {
    id: nanoid(),
    img: 'smart-contract.png',
    title: 'Course Project - Decentralized Supply Chain Tracking for Luxury Goods',
    info:
      "This project was a group project in CS6290 Privacy-enhancing Technologies of my master's degree study.",
    info2:
      'In this project, blockchain and smart contract had been adopted to bring benefits into supply chain processes. I wrote a Solidity smart contract atop Ethereum to demonstrate the idea of decentralized supply chain tracking for luxury good. The project demonstrated how to integrate smart contract into supply chain industry with transparency and immutablility.',
    info3: 'Key Techs: Solidity/Ethereum/Smart Contract',
    url: 'https://drive.google.com/file/d/1tbrLr2PQcaJVLa-Ajhnmvv73pjHyw2Sz/view',
  },
  {
    id: nanoid(),
    img: 'ec2-vs-fargate.png',
    title: 'Course Project - EC2 vs FARGATE launch types in Amazon Elastic Container Service (ECS)',
    info:
      "This project was a group project in CS5296 Cloud Computing: Theory and Practice of my master's degree study. ",
    info2:
      'The aim of this project was to study the differences between EC2 and Fargate launch types given in the same task definition. With the same task definition, the evaluations showed that EC2 launch type provided a higher performance in aspects of CPU, memory, and networking.',
    info3: 'Key Techs: Amazon ECS/Amazon EC2/Amazon Fargate',
    url: 'https://drive.google.com/file/d/14J1QHoaQqjieA2pLAk5l8dsJOg7GpIom/view',
  },
  {
    id: nanoid(),
    img: 'onboarding.png',
    title: 'Jobable - Onboarding Solution',
    info:
      'Jobable Onboarding was a digitial onboarding solution to provide better onboarding experience to new hires. This solution was awarded as the Best Employee Engagement Software in 2019.',
    info2:
      'I was responsible for building this solution in a reuse and replicable manner from scratch. I also introduced the new stacks to the team - new DevOps pipeline for CI/CD (AWS CodeBuild and AWS CodePipeline) and DynamoDB. This solution has been succeed and adopted in various corporates including Swire Groups, Cigna, etc.',
    info3: 'Key Techs: Node.js/Express.js/Vue.js/Nuxt.js/AWS ECS/DynamoDB',
    url: 'https://drive.google.com/file/d/1P62uRWEYp7dPbk4KuKVWMhemgonFpe0l/view?usp=sharing',
  },
  {
    id: nanoid(),
    img: 'virtual-tour.png',
    title: 'Jobable - VR Solution',
    info:
      'Jobable VR solution was one of the rapid prototyping innovation project that succeed to meet the client needs.',
    info2:
      'I was responsible for conducting research on creating VR solutions. I developed a boilerplate from scratch that was capable of developing new VR project in a short time. This solution has been delivered to various corporates including Citi bank, BOCI Group, etc.',
    info3: 'Key Techs: aframe/Nuxt.js/AWS CloudFront',
    url: 'https://landing.jobable.com/virtual-reality',
  },
  {
    id: nanoid(),
    img: 'jobs-board.png',
    title: 'Jobable - Jobs Board',
    info:
      'Jobs Board was the first project that I worked on in Jobable. This project was expanded to Singapore and Philippines in 2017.',
    info2:
      'As an intern in the team, I quickly picked up the existing tech stacks (Express.js, Symfony, and Pug) and developed an internal portal and slack bot for daily business operations. After 3 months of working as an intern, I continued my career in Jobable as a full time Software Engineer. I was responsible for improving the existing product including jobs board, employer portal, and candidate targeting tools.',
    info3: 'Key Techs: PHP/Node.js/Express.js/Pug/JQuery/Scrapy/SEO Optimization',
    url: 'https://jobable.com',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'arfai1213@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/mingfailaw/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/arfai1213',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
